import React, {CSSProperties, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {myBoxShadow, WaitingAbs} from "../../../assets/styles/elements";
import {FaSpinner} from "react-icons/fa";
import ToolTipIconBtn from "./ToolTipIconBtn";

interface TimedIconButtonCtrlProps{
    className?:string;
    size: "xs" | "sm" | "medium" | "large" | "xl";
    themeColor?: "Primary" | "Secondary" | "Complementary" | "Tertiary" | "Warning" | "Success";
    Icon:React.ReactNode;
    onClick?:()=>void;
    onClick2?:(e:any)=>void;
    toolTip?:string;
    isToolTipR?:boolean;
    BackAlw?:boolean;
    isActive?:boolean;
    marginTop?:string;
    marginR?:string;
    isPending?:boolean;
    isDisabled?:boolean;
    isSquare?:boolean;

}
function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

const TimedIconButtonCtrl = ({className, Icon, onClick, onClick2, toolTip, isActive, BackAlw, size, isPending, isDisabled}:TimedIconButtonCtrlProps)=>{
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [isDepasse, setIsDepasse] = useState<boolean>(false);
    const [tooltipVisible, setToolTipVisible] = useState(false)
    const [coordToolTip, setCoordToolTip] = useState<CSSProperties>({left:"0", top:"0", right:"auto"})
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const myRef = useRef<HTMLDivElement>(null);
    useEffect(()=>{
        if(myRef.current) {
            const myElt = myRef.current.getBoundingClientRect();
            const myWidth = size === "sm" ? 28 : size === "medium" ? 32 : size === "large" ? 40 : 48;
            const myRight = myElt.left + myWidth + 3*(toolTip ? toolTip.length : 0);
            setIsDepasse(myRight > windowSize.innerWidth);
        }
    }, [myRef, windowSize, toolTip, size])
    const handleClick = (e:any)=>{
        if(!isDisabled){
            if(onClick) onClick()
            if(onClick2) onClick2(e)
        }
    }
    const handleHover = ()=>{
        if(myRef.current){
            const myElt = myRef.current.getBoundingClientRect();
            const top = myElt.top;
            const left = myElt.left;
            const right = window.innerWidth - myElt.right;
            const height = myElt.height;
            if(!isDepasse){
                setCoordToolTip({top:`${top+height+10}px`, left:left+"px", right:"auto", transform:"translateX(-50%)"})
            } else {
                setCoordToolTip({top:`${top+height+10}px`, left:"auto", right:`${right}px`})
            }
            setToolTipVisible(true);
        }
    }
    const handleUnhover = ()=>{
        setToolTipVisible(false);
    }
    return (
        <div ref={myRef} onMouseOver={handleHover} onMouseOut={handleUnhover} className={`TimedIconBtn ${isDisabled ? 'disabled' : ''} ${className} ${isActive ? 'Active' : ''} ${BackAlw ? 'isBack' : ''} ${isDepasse ? 'depassing' : ''}`} onClick={handleClick}>
            {isPending &&
                <div className={`pending-zone`}><FaSpinner/></div>
            }
            {Icon}
            {toolTip &&
                <ToolTipIconBtn coord={coordToolTip} libelle={toolTip ? toolTip : ""} isVisible={tooltipVisible}/>
            }
        </div>
    )
}



const TimedIconButton = styled(TimedIconButtonCtrl)`
  flex-shrink: 0;
  .pending-zone{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    svg{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      font-size: ${props=>props.size === "xs" ? "16px" : props.size === "sm" ? "20px" : props.size === "medium" ? "24px" : props.size === "large" ? "30px" : "35px"};
    }
    animation: ${WaitingAbs} linear 0.7s infinite;
  }
    width: ${props=>props.size === "xs" ? "22px" : props.size === "sm" ? "28px" : props.size === "medium" ? "32px" : props.size === "large" ? "40px" : "48px"};
    height: ${props=>props.size === "xs" ? "22px" : props.size === "sm" ? "28px" : props.size === "medium" ? "32px" : props.size === "large" ? "40px" : "48px"};
  border-radius: ${props=>props.isSquare ? '4px' :  "50%"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${props=>props.marginTop ? props.marginTop : '0'};
  margin-right: ${props=>props.marginR ? props.marginR : '0'};
  position: relative;
  background: ${props=>props.isDisabled ? props.theme.PrimaryExtraLight : !props.BackAlw ? 'transparent': props.themeColor === "Primary" ? props.theme.PrimaryExtraLight :
          props.themeColor === "Secondary" ? props.theme.SecondaryExtraLight :
                  props.themeColor === "Complementary" ? props.theme.ComplementaryExtraLight : props.themeColor === "Warning" ? props.theme.WarningExtraLight
                          : props.themeColor === "Success" ? props.theme.SuccessExtraLight : props.theme.TertiaryExtraLight};
  &.Active{
    svg{
      color: white;
      path{
        stroke:white;
      }
    }
    background: ${props=>props.isDisabled ? props.theme.PrimaryExtraLight : props.themeColor === "Primary" ? props.theme.Primary :
            props.themeColor === "Secondary" ? props.theme.Secondary :
                    props.themeColor === "Complementary" ? props.theme.Complementary :
                            props.themeColor === "Warning" ? props.theme.Warning: props.themeColor === "Success" ? props.theme.Success :
                            props.theme.Tertiary};
  }
  &.isBack{
    &:hover{
      svg{
        color: white;  
      }
      background: ${props=>props.isDisabled ? props.theme.PrimaryExtraLight : props.themeColor === "Primary" ? props.theme.Primary :
              props.themeColor === "Secondary" ? props.theme.Secondary :
                      props.themeColor === "Complementary" ? props.theme.Complementary:
                              props.themeColor === "Warning" ? props.theme.Warning: props.themeColor === "Success" ? props.theme.Success : props.theme.Tertiary};
    }
  }
  svg{
    margin: auto;
    font-size: ${props=>props.size === "xs" ? "12px" : props.size === "sm" ? "12px" : props.size === "medium" ? "16px" : props.size === "large" ? "24px" : "28px"};
    color: ${props=>props.themeColor === "Primary" ? props.theme.Primary :
            props.themeColor === "Secondary" ? props.theme.Secondary :
                    props.themeColor === "Complementary" ? props.theme.Complementary:
                            props.themeColor === "Warning" ? props.theme.Warning: props.themeColor === "Success" ? props.theme.Success : props.theme.Tertiary};
  }
  &:hover{
    cursor: pointer;
    background: ${props=>props.themeColor === "Primary" ? props.theme.PrimaryExtraLight :
            props.themeColor === "Secondary" ? props.theme.SecondaryExtraLight :
                    props.themeColor === "Complementary" ? props.theme.ComplementaryExtraLight:
                            props.themeColor === "Warning" ? props.theme.WarningExtraLight: props.themeColor === "Success" ? props.theme.SuccessExtraLight : props.theme.TertiaryExtraLight};
  }
`

export default TimedIconButton;