import React from "react";
import styled from "styled-components";
import waity from "../../../assets/images/wait-data.png";

interface TimedAlertChoiceCtrlProps{
    className?:string;
    children:React.ReactNode;
    title?:string;
    width?:string;
    isFullH?:boolean;
}

const TimedAlertChoiceCtrl = ({className, children, title}:TimedAlertChoiceCtrlProps)=>{
    return (
        <div className={`noChoiceAlert ${className}`}>
            <div className="content_noChoice_alert">
                <div className={`image-place`}>
                    <img src={waity} alt={"en attente elts"}/>
                </div>
                <div className={"legend"}>{title ? title : 'En attente des éléments'}</div>
                <div className={"describe"}>
                    {children}
                </div>
            </div>
        </div>
    )
}

const TimedAlertChoiceStyled = styled(TimedAlertChoiceCtrl)`
  padding: 4rem 1rem;
  background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  //margin: auto;
  width: ${props => props.width ? props.width : "100%"};
    height: ${props=>props.isFullH ? "100%" : "auto"};
    
  .hightlight{
    font-weight: bold;
    color: black;
  }
  .describe{
    width: 80%;
    color: ${props=>props.theme.Primary};
    text-align: center;
    margin: auto;
    padding: 1rem;
  }
  .legend{
    padding: 0.5rem;
    text-align: center;
    font-size: 18px;
    color: ${props=>props.theme.Primary};
    font-weight: bold;
  }
  .image-place{
    width: 150px;
    margin: auto;
    img{
      width: 100%;
      height: auto;
    }
  }
`

const TimedAlertChoice = React.memo(TimedAlertChoiceStyled);

export default TimedAlertChoice;