import styled from "styled-components";
import {BsCalendarDate, BsClockFill, BsFillCalendar3WeekFill, BsMegaphoneFill, BsPersonLinesFill} from "react-icons/bs";
import {MdBadge, MdDashboard} from "react-icons/md";
import {HiSwitchHorizontal} from "react-icons/hi";
import {FaUmbrellaBeach, FaWhmcs} from "react-icons/fa";
import {IoChatbubbleEllipses} from "react-icons/io5";
import React, {useEffect, useMemo, useState} from "react";
import {OneIconObject} from "../../OneSection";
import {useGetUser} from "../../../../../features/user/user.hooks";
import {useGetCentreActuel} from "../../../../../features/centre/centre.hooks";
import {useGetRoleNiveaux} from "../../../../../features/roleNiveau/roleNiveau.hooks";
import {useGetModules} from "../../../../../features/module/module.hooks";
import {useLocation, useNavigate} from "react-router-dom";
import ListModules from "./ListModules";

interface OneSectionStyle2CtrlProps{
    className?:string;
    Section:Section;
}

const TabIcon:OneIconObject[]=[
    {lib:"BsPersonLinesFill", comp:<BsPersonLinesFill/>},
    {lib:"BsFillCalendar3WeekFill", comp:<BsFillCalendar3WeekFill/>},
    {lib:"BsClockFill", comp:<BsClockFill/>},
    {lib:"MdBadge", comp:<MdBadge/>},
    {lib:"HiSwitchHorizontal", comp:<HiSwitchHorizontal/>},
    {lib:"FaUmbrellaBeach", comp:<FaUmbrellaBeach/>},
    {lib:"IoChatbubbleEllipses", comp:<IoChatbubbleEllipses/>},
    {lib:"BsMegaphoneFill", comp:<BsMegaphoneFill/>},
    {lib:"MdDashboard", comp:<MdDashboard/>},
    {lib:"FaWhmcs", comp:<FaWhmcs/>},
]

const OneSectionStyle2Ctrl = (props:OneSectionStyle2CtrlProps)=>{
    const UserQuery = useGetUser();
    const CentreQuery = useGetCentreActuel();
    const RoleNiveauQuery = useGetRoleNiveaux();
    const ModulesQuery = useGetModules();
    const [openSS, setOpenSS] = useState(false)
    const [current, setCurrent] = useState(false)
    const {pathname} = useLocation()

    const myModules = useMemo(()=>{
        const myList:Module[] = [];
        if(UserQuery.data && RoleNiveauQuery.data && ModulesQuery.data && CentreQuery.data){
            const rolesUser = UserQuery?.data?.roles?.filter(r=>r!=='ROLE_ADMIN');
            let niveauUser = 0;
            if(RoleNiveauQuery.data) {
                RoleNiveauQuery.data.map(item => {
                    if (rolesUser?.indexOf(item.libelle) !== -1 && item.niveau > niveauUser) {
                        niveauUser = item.niveau;
                    }
                    return item;
                })
            }

            const MyModules = ModulesQuery.data.filter(m=>m.sectionId === props.Section.id && m.niveau<=niveauUser)
            MyModules.forEach(m=> {
                const Param = m.Parameter;
                if (!Param) {
                    myList.push(m);
                } else {
                    const ParamsCentre = CentreQuery.data.parameters;
                    const GoodParams = ParamsCentre.find(x => x.Parameter.id === Param.id);
                    if (!GoodParams || GoodParams.statut) {
                        myList.push(m);
                    }
                }
            });
        }
        return myList;
    }, [props.Section, UserQuery.data, RoleNiveauQuery.data, ModulesQuery.data, CentreQuery.data])
    useEffect(() => {
        const Tab = pathname.split("/")
        const modActive = Tab[2];
        const modAct = myModules.find(m=>m.url === modActive)
        if(modAct){
            setCurrent(true);
        } else {
            setCurrent(false)
        }
    }, [pathname, myModules]);
    const getIcon = ()=>{
        const lib = props.Section.icon;
        const myIconTab = TabIcon.find(i=>i.lib===lib);
        if(myIconTab!==undefined){
            return myIconTab.comp;
        } else {
            return <BsCalendarDate/>
        }
    }
    const navigate = useNavigate();
    const {search} = useLocation();
    const ClickSection = ()=>{
        if(myModules.length === 1){
            navigate(`${myModules[0].url}${search}`)
        }
    }
    return (
        <div className={`oneSectionStyle2 ${props.className} ${current ? "current" : ""}`} onMouseOver={()=>setOpenSS(true)} onMouseLeave={()=>setOpenSS(false)}>
            <div className="wrap_section_lib" onClick={ClickSection}>
                <div className="wrap_icon_section">{getIcon()}</div>
                <div className="wrap_name_section">{myModules.length === 1 ? myModules[0].libelle : props.Section.libelle}</div>
            </div>
            {myModules.length > 1 &&
                <div className={`wrap_sous_menu ${openSS ? "opened" : "closed"}`}>
                    <ListModules Modules={myModules.sort((a,b)=>a.ordreAffichage > b.ordreAffichage ? 1 :-1)}/>
                </div>
            }
        </div>
    )
}

const OneSectionStyle2 = styled(OneSectionStyle2Ctrl)`
    width: 90px;
    position: relative;
    z-index: 12;
    color: white;
    &.current{
        z-index: 10;
        .wrap_section_lib{
            background: ${props => props.theme.PrimaryDark};
            color: white;
        }
    }
    .wrap_sous_menu{
        position: absolute;
        left: calc(100% - 5px);
        background: white;
        top: -20%;
        transform: scale(1);
        padding: 0.5rem 0;
        border-radius: 6px;
        box-shadow: 0 3px 4px 0 rgba(0,0,0,.14),0 3px 3px -2px rgba(0,0,0,.12),0 1px 8px 0 rgba(0,0,0,.2);
        transition: transform 0.3s;
        z-index: 11;
        &.closed{
            transition: transform 0.3s;
            transform: scale(0);
        }
    }
    .wrap_section_lib{
        border-radius: 6px;
        text-align: center;
        .wrap_icon_section{
            width: 100%;
            padding: 0.25rem;
            svg{
                font-size: 22px;
                margin: auto;
            }
        }
        .wrap_name_section{
            font-size: 12px;
            width: 100%;
            padding-bottom: 0.25rem;
            
        }
        &:hover{
            cursor: pointer;
            background: ${props => props.theme.PrimaryExtraLight};
            color: ${props => props.theme.PrimaryExtraDark};
        }
    }
`

export default OneSectionStyle2