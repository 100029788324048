import {dataFetcher} from "../../services/dataGuetter/dataFetcher";


export async function getOneDayOneUser(idUser:number, date:string, idCentre:number|null):Promise<PointeuseTreat[]>{
    let params = `User=${idUser}&dateAT=${date}&pagination=false&isCalculate=1`
    if(idCentre){
        params+=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`pointeuse_treats?${params}`, {method:'get'})
    return res["hydra:member"]
}

export function getOnePointeuseTreat(id:number):Promise<PointeuseTreat>{
    return dataFetcher(`pointeuse_treats/${id}`, {method:'get'})
}

export async function getPTbyParams(params:any|null):Promise<PointeuseTreat[]>{
    let TabSearch:string[] = [];
    if(params) {
        for (const [key, value] of Object.entries(params)){
            TabSearch.push(`${key}=${value}`)
        }
    }
    const search = TabSearch.length>0 ? `${TabSearch.join('&')}&pagination=false` : 'pagination=false'
    const res:any = await dataFetcher(`pointeuse_treats?${search}`, {method:'get'})
    return res["hydra:member"];
}

export async function getPointeuseTreatPage(page:string, params:any|null):Promise<PointeuseTreatPage>{
    let TabSearch:string[] = [];
    if(params) {
        for (const [key, value] of Object.entries(params)){
            TabSearch.push(`${key}=${value}`)
        }
    }
    const search = TabSearch.length>0 ? `&${TabSearch.join('&')}&pagination=true` : '&pagination=true'
    const res:any = await dataFetcher(`${page}${search}`, {method:'get'})
    const result = res["hydra:member"];
    const TabView = res["hydra:view"];

    const nextPage =  "hydra:next" in TabView ? TabView["hydra:next"].replace("/api/", "") : undefined;
    const first = res["hydra:view"]["hydra:last"];
    const last = res["hydra:view"]["hydra:first"];
    const total = res["hydra:totalItems"];

    return {result, nextPage, first, last, total}
}

export function SavePointeuseTreat(datas:PointeuseTreatModifyFormData):Promise<PointeuseTreat>{
    return dataFetcher(`pointeuse_treats/${datas.id}`, {
        method:'PUT',
        body:datas
    })
}

export function SavePointeuseTreatReso(datas:PTResoPost):Promise<PointeuseTreat>{
    return dataFetcher(`pointeuse_treats/${datas.id}/resolution`, {
        method:'post',
        body:datas
    })
}

export function cancelPT(id:number):Promise<PointeuseTreat>{
    return dataFetcher(`pointeuse_treats/${id}/cancel_regule`, {
        method:'POST',
        body:{}
    })
}

export function addReguleTemps(datas:ReguleTpsFD, idUserConnected:number):Promise<PointeuseTreat>{
    if(idUserConnected!==0){
        datas = {...datas, updateBy:`/api/users/${idUserConnected}`}
    }
    return dataFetcher(`pointeuse_treats`, {
        method:'post',
        body:datas
    })
}

export function changeFerie(datas:ChangeFerieFD, id:number):Promise<PointeuseTreat>{
    return dataFetcher(`pointeuse_treats/${id}/change_ferie`, {
        method:'post',
        body:datas
    })
}

export function resolutionPlanning(ids:number[]):Promise<PointeuseTreat[]>{
    return dataFetcher(`pointeuse_treats/resolution_planning`, {
        method:'post',
        body: {ids:ids.join('<#>')}
    })
}

export function resolutionPause(idPT:number, idFor:number):Promise<PointeuseTreat>{
    return dataFetcher(`pointeuse_treats/${idPT}/resolution_pause`, {
        method:'post',
        body: {id:idFor}
    })
}

export function getMetrixHebdo(id:number, date:string):Promise<MetrixHebdo>{
    return dataFetcher(`get_metrix_contrat_semaine/${id}?date=${date}`)
}

export function deletePT(id:number):Promise<any>{
    return dataFetcher(`pointeuse_treats/${id}`, {
        method:"delete",
    })
}