import {useQuery, UseQueryResult} from "react-query";
import {getAlerteCentre} from "./alerteCentre.services";


export const useGetAlerteCentre = (id:number):UseQueryResult<AlerteCentre, Error>=>{
    return useQuery<AlerteCentre, Error>(["alertes", id], ()=>getAlerteCentre(id), {
        enabled:id!==0,
        staleTime:120000,
        suspense:false
    })
}