import styled from "styled-components";
import {Shine} from "../../../assets/styles/elements";

interface TimedHeaderSuspenseCtrlProps{
    className?:string;
}

const TimedHeaderSuspenseCtrl = (props:TimedHeaderSuspenseCtrlProps)=>{
    return (
        <div className={`timedHeaderSuspense ${props.className}`}>
            <div className="block_sus"></div>
        </div>
    )
}

const TimedHeaderSuspense = styled(TimedHeaderSuspenseCtrl)`
    width: 100%;
    padding: 1rem 1.3rem 1rem;
    height: 69px;
    gap: 8px;
    .block_sus{
        width: 100%;
        height: 36px;
        background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
        background-size: 200% 100%;
        animation: 1.5s ${Shine} linear infinite;
    }
`

export default TimedHeaderSuspense