import styled from "styled-components";
import {useEffect, useRef} from "react";
import ReactDOM from "react-dom";

interface ListChoix324CtrlProps {
    className?:string;
    ChoiceFiltered:Choice[];
    Choices:Choice[];
    Current?:Choice;
    x:number,
    y:number,
    closeMe:()=>void;
    setChoice:(c:Choice)=>void;
    id:string;
    Zindex?:number;
    open?:boolean;
}

const ListChoix324Ctrl = ({...props}: ListChoix324CtrlProps) => {
    const refObjt = useRef<HTMLDivElement>(null)
    useEffect(() => {
        function handleClickOutside(event:any) {
            const myIdInputRef = document.getElementById("input"+props.id)
            if (refObjt.current && !refObjt.current.contains(event.target) && (!myIdInputRef || !myIdInputRef.contains(event.target))) {
                props.closeMe();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObjt, props.id, props]);
    if(!props.open){
        return null;
    }
    return ReactDOM.createPortal(
        <div className={`list_choix_324 ${props.className}`} ref={refObjt} style={{left:props.x+"px", top:props.y+"px", zIndex:props.Zindex ? props.Zindex : 4}}>
            <div className="wrap_filtered">
                {props.ChoiceFiltered.length === 0 ?
                    <div className="no_filter">Aucun correspondant</div>:
                    props.ChoiceFiltered.length === props.Choices.length ?
                        <></>:
                    <>
                        {props.ChoiceFiltered.sort((a, b) => a.libelle > b.libelle ? 1 : -1).map((item) => (
                            <div className="on_choice_list" key={`oneChoiceListF${item.id}`}
                                 onClick={() => props.setChoice(item)}>
                                {item.bkgColor &&
                                    <div className="square_color" style={{background: item.bkgColor}}></div>
                                }
                                <span className={"span_choice"}>{item.libelle}</span>
                            </div>

                        ))}
                    </>
                }
            </div>
            <div className="all_choice">
                {props.Choices.sort((a,b)=>a.libelle >b.libelle ? 1 : -1).map((item) => (
                    <div className="on_choice_list" key={`oneChoiceListA${item.id}`}
                         onClick={() => props.setChoice(item)}>
                        {item.bkgColor &&
                            <div className="square_color" style={{background:item.bkgColor}}></div>
                        }
                        <span className={"span_choice"}>{item.libelle}</span>
                    </div>
                ))}
            </div>
        </div>, document.body
    )
}

export const ListChoix324 = styled(ListChoix324Ctrl)`
    display: block;
    width: max-content;
    position: fixed;
    background: white;
    padding: 0.25rem;
    border-radius: 4px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.14),0 3px 3px -2px rgba(0,0,0,.12),0 1px 8px 0 rgba(0,0,0,.2);
    
    max-height: 350px;
    overflow-y: auto;
    .square_color{
        width: 6px;
        height: 6px;
        border:solid 1px transparent;
    }
    .wrap_filtered{
        padding-bottom: 0.5rem;
        border-bottom: solid 1px ${props => props.theme.NeutreExtraLight};
    }
    .all_choice{
        padding-top: 0.5rem;
    }
    .on_choice_list{
        padding: 0.30rem 0.75rem 0.30rem 0.5rem;
        font-size: 15px;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        align-items: center;
        &:hover{
            background: ${props => props.theme.PrimaryMegaLight};
            cursor: pointer;
            .square_color{
                border-color: ${props=>props.theme.NeutreDark};
            }
        }
    }
`