import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {useGetNumberModule} from "../../../../../features/moduleFxNumber/moduleFxNumber.hooks";

interface LinkModuleCtrlProps{
    className?:string;
    Module:Module;
}

const LinkModuleCtrl = (props:LinkModuleCtrlProps)=>{
    const { search } = useLocation();
    const location = useLocation();
    const [current, setCurrent] = useState(false);
    const [nb, setNb] = useState(0)
    const FxNumberQuery = useGetNumberModule(props.Module.fxNumber ? +props.Module.id :0)
    useEffect(() => {
        if(FxNumberQuery.data){
            console.log(props.Module.id)
            console.log(FxNumberQuery.data.volume)
            setNb(FxNumberQuery.data.volume);
        }
    }, [FxNumberQuery.data]);
    useEffect(() => {
        const TabPath = location.pathname.split('/');
        const myPath = TabPath[2];
        setCurrent(myPath === props.Module.url);
    }, [location.pathname, props.Module]);
    const GetUrl = ()=>{
        return props.Module.url
    }
    return (
        <NavLink to={`${GetUrl()}${search}`} className={`linkModule ${props.className} ${current ? 'is_current' : ''} ${props.Module.isGroup ? "marg_top" : ""}`}>
            {props.Module.libelle}
            {nb!==0 &&
                <span className="numberX">({nb})</span>
            }
        </NavLink>
    )
}

const LinkModule = styled(LinkModuleCtrl)`
    display: block;
    text-decoration: none;
    color: ${props => props.theme.Dark};
    padding: 0.5rem 1rem;
    white-space: nowrap;
    &.marg_top{
        margin-top: 12px;
    }
    &:hover{
        cursor: pointer;
        background: ${props => props.theme.PrimaryExtraLight};
        color: ${props => props.theme.PrimaryExtraDark};
    }
`

export default LinkModule