import {useMutation, useQuery, useQueryClient, UseQueryResult} from "react-query";
import {
    GetAllLieuVPByVue,
    getAllVues,
    PostNewLieuVuePlanning,
    PostNewVuePlanning,
    TrashLieuVuePlanning
} from "./vuePlanning.services";


export const useGetVuePl = (idCentre:number|null=null):UseQueryResult<VuePlanning[], Error>=>{
    let idCentreX = idCentre;
    const queryClient = useQueryClient();
    const UserConnected: UserSimple | undefined = queryClient.getQueryData(["user_connected"]);
    const idCentreXConnected = UserConnected ? UserConnected.Centre.id : 0;
    const idCentreFinal = idCentreX ? idCentreX : idCentreXConnected
    return useQuery<VuePlanning[], Error>(["vue_plannings", idCentre], ()=>getAllVues(idCentreFinal), {
        staleTime:60000*10,
        cacheTime:60000*15,
        enabled:idCentreFinal!==0
    })
}

export const useGetAllLieuVuePlanningByVP = (id:number):UseQueryResult<LieuVuePlanning[], Error>=>{
    return useQuery(["lieu_vue_planning", id], ()=>GetAllLieuVPByVue(id), {
        enabled:id!==0
    })
}

export const useCreateNewLieuVuePlanning = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:PostLieuVuePlanningProps)=>{
            return PostNewLieuVuePlanning(datas)
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["lieu_vue_planning"])
                queryClient.invalidateQueries(["lieux"])
                queryClient.invalidateQueries(["lieux_centre"])
            }
        }
    )
}

export const useTrashLieuVuePlanning = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (id:number)=>{
            return TrashLieuVuePlanning(id)
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["lieu_vue_planning"])
                queryClient.invalidateQueries(["lieux"])
                queryClient.invalidateQueries(["lieux_centre"])
            }
        }
    )
}

export const useAddVuePlanning = ()=>{
    const queryClient = useQueryClient();
    return useMutation(
        (datas:PostVuePlanning)=>{
            return PostNewVuePlanning(datas)
        }, {
            onSuccess:()=>{
                queryClient.invalidateQueries(["vue_plannings"]);
            }
        }
    )
}

