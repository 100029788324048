import {useQuery, UseQueryResult} from "react-query";
import {getNumberModule} from "./moduleFxNumber.services";


export const useGetNumberModule = (id:number):UseQueryResult<ModuleFxNumber, Error>=>{
    return useQuery<ModuleFxNumber, Error>(["modulefx", id], ()=>getNumberModule(id), {
        enabled:id!==0,
        staleTime:60000*5,
        cacheTime:60000*10,
        refetchOnWindowFocus:false
    })
}