import React from "react";
import styled from "styled-components";

interface HeadPageCtrlProps{
    className?:string;
    titre:string;
    subTitre?:string;
    children?:React.ReactNode;
    MargBottom?:string;
    noPadR?:boolean;
}

const HeadPageCtrl = ({className, titre,subTitre, children}:HeadPageCtrlProps)=>{
    return (
        <div className={`HeadPage ${className}`}>
            <div className={`wrap-titre`}>
                <div className={"titre-place"}>{titre}</div>
                {subTitre &&
                    <div className={"subtitre-place"}>{subTitre}</div>
                }
            </div>
            {children &&
                <div className={"actions-place"}>
                    {children}
                </div>
            }
        </div>
    )
}

const HeadPage = styled(HeadPageCtrl)`
    width: 100%;
    margin-bottom: ${props => props.MargBottom ? props.MargBottom :  "32px"};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${props=>props.noPadR ? "0.5rem 0" : "0.5rem 1rem 0.5rem 0"};
  .subtitre-place{
    color: ${props => props.theme.NeutreDark};
    font-size: 18px;
    margin-top: 12px;
  }
    .titre-place{
      flex-grow: 1;
      color:${props=>props.theme.Primary};
      font-size: 28px;
      font-weight: bold;
    }
  .actions-place{
    flex-grow: 1;
    display: flex;
    justify-content: end;
    gap: 5px;
  }
`
export default HeadPage;