import styled, {keyframes, useTheme} from "styled-components";
import {ThreeDots} from "react-loader-spinner";
import React from "react";

interface TimedDashWaiterProps{
    className?:string;
}

const TimedDashWaiterCtrl = (props:TimedDashWaiterProps)=>{
    const theme = useTheme();
    return (
        <div className={`timedDashWaiter ${props.className}`}>
            <div className="wrap_load">
                <div className="wrap_text">Chargement</div>
                <div className="wrap_spin">
                    <div className={"spinner"}>
                        <ThreeDots
                            height="30"
                            width="40"
                            radius="9"
                            color={theme.Primary}
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}

                            visible={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const TimedDashWaiter = styled(TimedDashWaiterCtrl)`
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80px;
    .wrap_load{
        margin: auto;
        .wrap_text{
            text-align: center;
        }
    }
    .wrap_spin{
        display: flex;
        justify-content: center;
    }
`

export default TimedDashWaiter