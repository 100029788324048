import styled from "styled-components";
import {useGetCentreActuel} from "../../features/centre/centre.hooks";
import React, {useEffect, useMemo, useState} from "react";
import {useGetAllModuleCarte} from "../../features/moduleCarte/moduleCarte.hooks";
import {DatasSetter, ValideNoError} from "../../components/atoms/TimedFormInteractif/Verifs";
import {useAddExport} from "../../features/export/export.hooks";
import {toast} from "react-toastify";
import {useGetAllTypeRapport} from "../../features/typeRapport/typeRapport.hooks";
import {TimedInputDate324} from "../../components/atoms/TimedForm324/TimedInputDate324";
import {TimedSelect324} from "../../components/atoms/TimedForm324/TimedSelect324";
import {TimedInputText324} from "../../components/atoms/TimedForm324/TimedInputText324";
import TimedButton from "../../components/atoms/TimedButton/TimedButton";


interface FormNewExportModalCtrlProps {
    className?:string;
    closeMe:()=>void;
}

interface NewExportProps{
    TypeRapport:Choice|null,
    FileName:string,
    dateStart:string,
    dateEnd:string,
}

const NewExportDefault:NewExportProps={
    TypeRapport:null,
    FileName:"",
    dateStart:"",
    dateEnd:"",
}

interface ErrorsProps{
    TypeRapport:string|null,
    FileName:string|null,
    dateStart:string|null,
    dateEnd:string|null,
}

const DefaultErrors:ErrorsProps ={
    TypeRapport:null,
    FileName:null,
    dateStart:null,
    dateEnd:null,
}

const FormNewExportModalCtrl = (props: FormNewExportModalCtrlProps) => {
    const mutation = useAddExport();
    const [myExport, setMyExport] = useState<NewExportProps>(NewExportDefault)
    const [errors, setErrors] = useState<ErrorsProps>(DefaultErrors)
    const TabChoice = ["TypeRapport"]
    const TabNumber:string[] = []
    const TabString = ["FileName", "dateStart", "dateEnd"];
    const TabNeed:MyConstraint[] = [
        {id:"FileName", required:true},
        {id:"dateStart", required:false},
        {id:"dateEnd", required:false},
        {id:"TypeRapport", required:true},
    ]
    const CentreQuery = useGetCentreActuel()
    const [idCentre, setIdCentre] = useState<number>(0)
    const TypesRapportQuery = useGetAllTypeRapport();
    const ModuleCarteQuery = useGetAllModuleCarte(idCentre)

    useEffect(() => {
        if(CentreQuery.data) setIdCentre(CentreQuery.data.id)
    }, [CentreQuery.data]);
    const setMyValue = (value:string|null|Choice|number, id:string)=>{
        DatasSetter<NewExportProps, ErrorsProps>(value, id as keyof NewExportProps, TabString, TabNumber, TabChoice, TabNeed, myExport, setMyExport,errors,setErrors)
    }
    const listType = useMemo(()=>{
        const list:Choice[] = [];
        if(ModuleCarteQuery.data && TypesRapportQuery.data){
            const today = new Date((new Date().setHours(12,0,0)))
            const MyModules = ModuleCarteQuery.data.filter(mc=>new Date(mc.startAt.slice(0,10)+"T12:00:00") < today && (!mc.endAt || new Date(mc.endAt.slice(0,10)+"T12:00:00")>today)).map(mc=>mc.ModuleFact.id);
            console.log(MyModules);
            TypesRapportQuery.data.forEach(tr=>{
                if(MyModules.indexOf(tr.ModuleFact.id)!==-1 && tr.production){
                    list.push({id:tr.id, libelle:tr.libelle})
                } else {
                    console.log(tr.ModuleFact.id);
                }
            })
        }
        return list.sort((a,b)=>a.libelle > b.libelle ? 1 : -1);
    }, [ModuleCarteQuery.data, TypesRapportQuery.data])
    const Submit = ()=>{
        if(ValideNoError<ErrorsProps>(errors) && myExport.TypeRapport && ((myExport.dateStart!=="" && myExport.dateEnd!=="") || myExport.TypeRapport.id === 1 )){
            const datas:ExportNewFD = {
                FileName:myExport.FileName,
                TypeRapport:`/api/type_rapports/${(myExport.TypeRapport.id as number)}`
            }
            if(myExport.dateStart!==""){
                datas.dateStartAt=myExport.dateStart
            }
            if(myExport.dateEnd!==""){
                datas.dateEndAt=myExport.dateEnd
            }
            mutation.mutate(datas, {
                onSuccess:()=>{
                    props.closeMe()
                } , onError:()=>{
                    toast.error("Impossible de créer l'export")
                }
            })
        } else {
            console.log("PB")
            console.log(ValideNoError<ErrorsProps>(errors))
            console.log(myExport.TypeRapport ? "ok type" : "pb type")
        }
    }
    return (
        <div className={`new_export_modal ${props.className}`}>
            <TimedSelect324
                id={"TypeRapport"}
                Choices={listType}
                Current={myExport.TypeRapport}
                setValue={setMyValue}
                Error={errors.TypeRapport}
                label={"Type Rapport*"}
            />
            <TimedInputText324 Error={errors.FileName} label={"Nom du fichier*"} id={"FileName"} Current={myExport.FileName} setVal={setMyValue}/>
            {(!myExport.TypeRapport ||myExport.TypeRapport.id !==1) &&
                <>
                    <TimedInputDate324 Error={errors.dateStart} label={"Date début*"} id={"dateStart"} Current={myExport.dateStart} setVal={setMyValue}/>
                    <TimedInputDate324 Error={errors.dateEnd} label={"Date fin*"} id={"dateEnd"} Current={myExport.dateEnd} setVal={setMyValue}/>
                </>
            }
            <div className={`wrap_send`}>
                <TimedButton themeColor={"Primary"} isFull size={"sm"} text={"Enregistrer"} onClick={Submit}/>
            </div>
        </div>
    )
}

export const FormNewExportModal = styled(FormNewExportModalCtrl)`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 18px;
`