import styled from "styled-components";
import {createPortal} from "react-dom";
import {myBoxShadow} from "../../../assets/styles/elements";
import {CSSProperties} from "react";

interface ToolTipIconBtnCtrlProps{
    className?:string;
    libelle:string;
    coord:CSSProperties
    isVisible:boolean;
}

const ToolTipIconBtnCtrl = (props:ToolTipIconBtnCtrlProps)=>{
    if(!props.isVisible){
        return null;
    }
    return createPortal(
        <div className={`ToolTipIconBtn ${props.className} ${props.isVisible ? "visible" : "invisible"}`} style={props.coord}>{props.libelle}</div>, document.body
    )
}

const ToolTipIconBtn = styled(ToolTipIconBtnCtrl)`
    position: fixed;
    z-index: 101;
    font-size: 12px;
    width: max-content;
    background: ${props=>props.theme.PrimaryDark};
    color: white;
    border-radius: 0.15rem;
    ${myBoxShadow};
    display: none;
    &.visible{
        padding: 0.25rem;
        display: block;
    }
`

export default ToolTipIconBtn