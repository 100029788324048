import {useMutation} from "react-query";
import {newSendEmail} from "./creneauEmailSender.services";


export const useSendEmailRplt = ()=>{
    return useMutation(
        (datas:CreneauEmailSenderFD)=>{
            return newSendEmail(datas);
        }
    )
}