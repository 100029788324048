import React, {createContext, Suspense, useContext, useState} from "react";
import styled from "styled-components";
import {Outlet} from "react-router-dom";
import TimedHeader from "../../molecules/TimedHeader/TimedHeader";
import {useModal} from "../../../hooks/useModal";
import TimedNotificationCurtain from "../../molecules/TimedNotificationsCenter/TimedNotificationCurtain";
import TimedSideBar from "../../molecules/_TimedSideBar/TimedSideBar";
import {TimedNotificationChat} from "../TimedNotificationChat";
import {TimedContext} from "../../../main/App";
import TimedSideBarStyle2 from "../../molecules/_TimedSideBar/TimedSideBar_2/TimedSideBarStyle2";
import TimedLoadPage from "../../atoms/TimedWaitUser/TimedLoadPage";
import TimedHeaderSuspense from "../../atoms/TimedWaitUser/TimedHeaderSuspense";

interface MainLayoutCtrlProps{
    className?:string;
}
export interface SideBarContext{
    setSectionOpened?:(s:Section|null)=>void;
    SectionOpened?:Section|null;
}

export const ContextSideBar = createContext<SideBarContext>({})

const MainLayoutCtrl = ({className}:MainLayoutCtrlProps)=>{

    const {open, toggle} = useModal();
    const CTimedContext = useContext(TimedContext);
    const [sectionOpened, setSectionOpened] = useState<Section|null>(null);
    const settingMyOpened = (s:Section|null)=>{
        setSectionOpened(s);
    }
    return (
        <div className={`MainLayout ${className}`}>
            <ContextSideBar.Provider value={{setSectionOpened:settingMyOpened, SectionOpened:sectionOpened}}>
                {!CTimedContext.isFullScreen &&
                    CTimedContext.myStyleSide === 2 ? <TimedSideBarStyle2/> :
                    <TimedSideBar/>
                }
                <div className={`MainLay`}>
                    {!CTimedContext.isFullScreen &&
                        <Suspense fallback={<TimedHeaderSuspense/>}>
                            <TimedHeader
                                OpenNotif={open}
                                SwitchNotif={toggle}
                            />
                        </Suspense>
                    }
                    <div className={`MainContent`} style={{height:!CTimedContext.isFullScreen ? "calc(100% - 90px)" : "100%"}}>
                        <Outlet/>
                    </div>
                    <TimedNotificationCurtain Open={open} SwitchOpen={toggle}/>
                </div>
            </ContextSideBar.Provider>
        </div>
    )
}

const MainLayout = styled(MainLayoutCtrl)`
  height: 100svh;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: stretch;
  
  .MainLay{
    overflow: hidden;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .MainContent{
        position: relative;
      overflow-y: auto;
      //height: calc(100% - 90px);
    }
  }
`

export default MainLayout;