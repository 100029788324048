import styled from "styled-components";
import LinkModule from "./LinkModule";

interface ListModulesCtrlProps{
    className?:string;
    Modules:Module[];
}

const ListModulesCtrl = (props:ListModulesCtrlProps)=>{
    return (
        <div className={`listModules ${props.className}`}>
            {props.Modules.map(m=>(
                <LinkModule Module={m} key={`Mo${m.id}`}/>
            ))}
        </div>
    )
}

const ListModules = styled(ListModulesCtrl)``

export default ListModules