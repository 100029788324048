import {useQuery, UseQueryResult} from "react-query";
import {getCompteurTempsDateDate, getCompteurTempsDateDateParams} from "./compteurTempsDateDate.services";


export const useGetCompteurTempsDateDate = (dateFrom:string, dateTo:string, users:number[], idCentre:number|null=null):UseQueryResult<CompteurTempsDateDate[], Error>=>{
    return useQuery(["cpt_tps_dd", {dateFrom, dateTo, users, idCentre}], ({signal})=>getCompteurTempsDateDate(dateFrom, dateTo, users, signal, idCentre), {
        enabled:dateFrom!=='' && dateTo!=='' && users.length>0 && Array.isArray(users) && users[0]!==0,
        refetchOnWindowFocus:false,
        suspense:false
    })
}

export interface ParamsCTDD{
    dateFrom:string;
    dateTo:string;
    users:number[];
    idCentre:number|null
}

export const useGetCompteurTempsDateDateParams = (params:ParamsCTDD|null):UseQueryResult<CompteurTempsDateDate[], Error>=>{
    return useQuery(["cpt_tps_dd", params], ()=>getCompteurTempsDateDateParams(params), {
        enabled:params!==null,
        refetchOnWindowFocus:false,
        suspense:false,
        staleTime:60000*10,
        cacheTime:60000*15,
    })
}