import styled from "styled-components";
import {ReactNode, useContext} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {SectionContext} from "./OneSection";

interface DirectLinkCtrlProps{
    className?:string;
    icon:ReactNode;
    Module:Module;
}

const DirectLinkCtrl = (props:DirectLinkCtrlProps)=>{
    const ContextSection = useContext(SectionContext);
    const { search } = useLocation();
    return (
        <NavLink to={`${props.Module.url}${search}`} className={`direct_link ${props.className}`} style={ContextSection.styleTitle}>
            <div className="icon_place">{props.icon}</div>
            <div className="lib_place">{props.Module.libelle}</div>
        </NavLink>
    )
}

export const DirectLink = styled(DirectLinkCtrl)`
  text-decoration: none;
  color: white;
  font-size: 15px;
  &:visited{
    color: white;
  }
  &:hover{
    cursor: pointer;
      border-radius: 6px;
    background: ${props=>props.theme.PrimaryLight};
    color: ${props=>props.theme.Light};
  }
`