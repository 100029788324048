import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {UseQueryResult} from "react-query";
import {myBoxShadow} from "../../../assets/styles/elements";
import TimedNotificationCenter from "../TimedNotificationsCenter/TimedNotificationCenter";
import {useGetCentreActuel} from "../../../features/centre/centre.hooks";
import {TimedSelectUrl} from "../../organisms/TimedSmartNav/TimedSelectUrl";
import {TimedSelectDates} from "../../organisms/TimedSmartNav/TimedSelectDates";
import {TimedSelectPersonneScope} from "../../organisms/TimedSmartNav/TimedSelectPersonneScope";
import {TimedSelectLieuxScope} from "../../organisms/TimedSmartNav/TimedSelectLieuxScope";
import TimedMenuHead from "../TimedMenuHead/TimedMenuHead";
import {isAuthSV, isAuthWM} from "../../../services/security/isAuth";
import TimedItemContext from "../../atoms/TimedItemContext/TimedItemContext";
import {TimedModalCentral} from "../TimedModal/TimedModalCentral";
import {FormShadowConnect} from "../../../pages/Forms/FormShadowConnect";
import {AiFillUnlock} from "react-icons/ai";
import {BiFilterAlt} from "react-icons/bi";
import {HiSwitchHorizontal} from "react-icons/hi";
import {FormGereFiltreHead} from "../../../pages/Forms/FormGereFiltreHead";
import {removeToken} from "../../../services/dataGuetter/dataFetcher";
import {useNavigate} from "react-router-dom";
import AvatAno from "../../../assets/images/Unisex-Person-with-Glasses.png";
import {BsCalendarCheck, BsTools} from "react-icons/bs";
import {ModalQuickHebdo} from "../../../pages/Modaux/ModalQuickHebdo";
import {MdCalculate} from "react-icons/md";
import {useModal} from "../../../hooks/useModal";
import {TimeCalculator} from "../../utils/TimeCalculator";
import {FormSwitchCentre} from "../../../pages/Forms/FormSwitchCentre";
import {useGetUser} from "../../../features/user/user.hooks";
import {TimedModalCentral2} from "../TimedModal/TimedModalCentral2";


interface TimedHeaderCtrlProps{
    className?:string;
    OpenNotif?:boolean;
    SwitchNotif?:Function;
}

const TimedHeaderCtrl = ({className, SwitchNotif, OpenNotif}:TimedHeaderCtrlProps)=>{
    const CentreQuery = useGetCentreActuel();
    const UserQuery = useGetUser();
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [openTools, setOpenTools] = useState<boolean>(false);
    const [openShadowConnect, setOpenShadowConnect] = useState<boolean>(false);
    const [openSwitchCentre, setOpenSwitchCentre] = useState<boolean>(false);
    const [openGereFiltres, setOpenGereFiltre] = useState<boolean>(false);
    const [filterHead, setFilterHead] = useState(false)
    const [openQuickPlanning, setOpenQuickPlanning] = useState(false);
    const {open:openCalc, toggle:toggleCalc} = useModal();
    useEffect(()=>{
        if(CentreQuery.data){
            const CentreParams = CentreQuery.data.parameters;
            const ParamsFilterHead = CentreParams.find(cp=>cp.Parameter.id === 16);
            if(ParamsFilterHead){
                setFilterHead(ParamsFilterHead.statut);
            }
        }
    }, [CentreQuery.data])
    const SwitchMenu = ()=>{
        setOpenMenu(o=>!o);
    }

    useEffect(()=>{
    }, [openMenu])
    const handleClickShadowConnect = ()=>{
        setOpenMenu(false);
        setOpenShadowConnect(true);
    }
    const handleClickSSwitchCentre = ()=>{
        setOpenMenu(false)
        setOpenSwitchCentre(true)
    }
    const handleClickGereFiltre = ()=>{
        setOpenMenu(false);
        setOpenGereFiltre(true);
    }
    const navigate = useNavigate();
    const Disconnect = ()=>{
        removeToken();
        navigate('/login');
    }
    const handleError = (e:any)=>{
        e.target.src = AvatAno;
    }
    return (
        <header className={`TimedH ${className}`}>
            <div className={`in_header`}>
                <div className={"one_square"}>
                    {CentreQuery.data &&
                        <img src={`${process.env.REACT_APP_API_URL_ROOT}/CentreLogos/${CentreQuery.data.logo}`} alt={'CentreLogo'}/>
                    }
                </div>
                <div className={`wrapSmart`}>
                    {UserQuery && UserQuery.data &&
                        <>
                            <TimedSelectUrl/>
                            <TimedSelectPersonneScope/>
                            <TimedSelectDates/>
                            <TimedSelectLieuxScope/>
                        </>
                    }
                </div>
                <div className={"space"}>
                    <div className={"one_square is_btn"}>
                        <div className={"wrapper_tool"}>
                            <div className={"btn_square"} onClick={()=>setOpenTools(o=>!o)}>
                                <BsTools/>
                            </div>
                            <TimedMenuHead
                                closeMe={()=>setOpenTools(false)}
                                TopPos={"120%"}
                                LeftPos={"-132px"}
                                open={openTools} Wd={"180px"} Ht={"auto"} TriggerNoClose={'btn_square'} isRightEffect
                            >
                                <TimedItemContext Action={()=> {
                                    setOpenTools(false);
                                    setOpenQuickPlanning(true);
                                }} label={"Hebdo rapide"} Icon={<BsCalendarCheck/>}/>
                                <TimedItemContext Action={()=>{
                                    setOpenTools(false);
                                    toggleCalc()
                                }} label={"Calculatrice de temps"} Icon={<MdCalculate />}/>
                            </TimedMenuHead>
                        </div>
                    </div>
                    <div className={"one_square is_btn"}>
                        {(!UserQuery.isLoading && UserQuery.data) &&
                            <TimedNotificationCenter User={UserQuery.data} OpenNotif={OpenNotif} SwitchOpen={SwitchNotif}/>
                        }
                    </div>
                    <div className={"one_square is_btn"}>
                        <div className={`wrap_round_head`}>
                            <div className={`square_head`} onClick={SwitchMenu}>
                                {UserQuery.isLoading ? '' : <img onError={handleError} src={`${process.env.REACT_APP_API_URL_ROOT}${UserQuery.data?.avatarUrl}`} alt={"user_avatar"}  className={"imgAvat"}/>}
                            </div>
                            <TimedMenuHead
                                closeMe={()=>setOpenMenu(false)}
                                TopPos={"120%"}
                                LeftPos={"-132px"}
                                open={openMenu} Wd={"180px"} Ht={"auto"} TriggerNoClose={'square_head'} isRightEffect
                            >
                                {isAuthWM() &&
                                    <TimedItemContext Action={handleClickShadowConnect} label={"Shadow Connect"} Icon={<HiSwitchHorizontal/>}/>
                                }
                                {isAuthSV() &&
                                    <TimedItemContext Action={handleClickSSwitchCentre} label={"Changement de structure"} Icon={<HiSwitchHorizontal/>}/>
                                }
                                {filterHead &&
                                    <TimedItemContext Action={handleClickGereFiltre} label={"Gestion des filtres"} Icon={<BiFilterAlt/>}/>
                                }
                                <TimedItemContext Action={Disconnect} label={"Déconnexion"} Icon={<AiFillUnlock/>}/>
                            </TimedMenuHead>
                        </div>
                    </div>

                </div>
            </div>
            <TimedModalCentral2 suspenser={true} noOverFlowHidden closeMe={()=>setOpenShadowConnect(false)} open={openShadowConnect} Pending={false} titre={"Shadow Connect"} Wd={"350px"}>
                {openShadowConnect &&
                    <FormShadowConnect/>
                }
            </TimedModalCentral2>
            <TimedModalCentral2 suspenser={true} Wd={"350px"} closeMe={()=>setOpenSwitchCentre(false)} open={openSwitchCentre} Pending={false} noOverFlowHidden titre={"Changement de structure"}>
                {openSwitchCentre &&
                    <FormSwitchCentre/>
                }
            </TimedModalCentral2>
            <TimedModalCentral2 suspenser={true} noOverFlowHidden closeMe={()=>setOpenGereFiltre(false)} open={openGereFiltres} Pending={false} titre={"Filtres"} Wd={"450px"}>
                {openGereFiltres &&
                    <FormGereFiltreHead closeMe={()=>setOpenGereFiltre(false)}/>
                }
            </TimedModalCentral2>
            <ModalQuickHebdo
                Orientation={"top"}
                Hei={68}
                UniteSize={"%"}
                open={openQuickPlanning}
                closeMe={()=>setOpenQuickPlanning(o=>!o)}
                titre={"Planning Rapide"}
                linkExpand={{libelle:"Planning Hebdo", link:`../planning_indi_hebdo`}}
            />
            <TimedModalCentral noOverFlowHidden closeMe={toggleCalc} open={openCalc} Pending={false} titre={"Calculatrice"} Wd={450}>
                <TimeCalculator/>
            </TimedModalCentral>
        </header>
    )
}

const TimedHeader = styled(TimedHeaderCtrl)`
  width: 100%;
  padding: 1rem 1.3rem 1rem;
  height: 69px;
  gap: 8px;
    .wrapper_tool{
        width: 36px;
        height: 36px;
        .btn_square{
            width: 36px;
            height: 36px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &:hover{
                cursor: pointer;
                background: ${props => props.theme.PrimaryMegaLight};
            }
            svg{
                margin: auto;

            }
        }
        position: relative;

    }
  .in_header{
    display: flex;
    justify-content: start;
    height: 36px;
    //background: white;
    align-items: center;
    border-radius: 4px;
    gap: 4px;
    
  }
  .one_square{
    width: 36px;
    ${myBoxShadow};
    border-radius: 4px;
    height: 100%;
    background: white ;
    &.is_btn:not(:last-child){
      border-right: solid ${props=>props.theme.NeutreExtraLight} 1px;
    }
    img{
      width: 100%;
      height: auto;
    }
  }
  .wrapSmart{
    display: flex;
    height: 100%;
    border-radius: 4px;
    background: white ;
    flex-grow: 1;
    ${myBoxShadow};
    justify-content: flex-start;
    // border-right: solid ${props=>props.theme.NeutreExtraLight} 1px;
    // border-left: solid ${props=>props.theme.NeutreExtraLight} 1px;
    //box-shadow: 1px 1px 3px rgba(0,0,0,.2);
  }
  .space{
    flex-shrink: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 4px;
  }
  .wrap_round_head{
    height: 100%;
    position: relative;
    &:hover{
      cursor: pointer;
    }
  }
  .square_head{
    width: 36px;
    flex-shrink: 0;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
    img{
      width: 100%;
      height: auto;
    }
  }
  .rest{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 8px;
  }
  //.wrapSmart{
  //  flex-grow: 1;
  //  width: 80%;
  //}
`

export default TimedHeader;