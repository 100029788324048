import jwt_decode from "jwt-decode";

const localStorageKey = "__timed_user_token__";
const localStorageKeyR = "__timed_user_refreshtoken__";

const API_BASE_URL = process.env.REACT_APP_API_URL;
const API_BASE_URL_ROOT = process.env.REACT_APP_API_URL_ROOT;


let isRefreshing = false;
let refreshSubscribers: Function[] = [];

async function refreshTokenWithLock(): Promise<string> {
    if (!isRefreshing) {
        isRefreshing = true;
        try {
            const newToken = await refreshToken();
            refreshSubscribers.forEach((callback) => callback(newToken));
            refreshSubscribers = [];
            return newToken;
        } finally {
            isRefreshing = false;
        }
    } else {
        return new Promise((resolve) => {
            refreshSubscribers.push(resolve);
        });
    }
}

async function refreshToken(): Promise<string> {
    const refreshToken = localStorage.getItem("__timed_user_refreshtoken__");
    if (!refreshToken) {
        removeToken();
        throw new Error("No refresh token available");

    }

    const response = await fetch(`${API_BASE_URL}/token/refresh`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refresh_token: refreshToken }),
    });

    if (!response.ok) {
        removeToken();
        throw new Error("Failed to refresh token");
    }

    const data = await response.json();
    localStorage.setItem("__timed_user_token__", data.token);
    localStorage.setItem("__timed_user_refreshtoken__", data.refresh_token);
    return data.token;
}


export async function fileFetcher<T>(endpoint:string, fileName:string):Promise<T|void>{
    const token = localStorage.getItem(localStorageKey);

    const headers: any = { Authorization: `Bearer ${token}` };

    const response = await fetch(`${API_BASE_URL_ROOT}${endpoint}`, { method: "GET", headers });

    if (!response.ok) {
        throw new Error("Failed to download file");
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
}

// const refreshToken = async <T>(): Promise<string>=>{
//     // const refresh_token =window.localStorage.getItem(localStorageKeyR)
//     // if(!refresh_token){
//     //     return '';
//     //     removeToken();
//     // }
//
//     const body = {refresh_token:window.localStorage.getItem(localStorageKeyR)}
//
//     let response = await fetch(`${process.env.REACT_APP_API_URL}/token/refresh`, {
//         method:'POST',
//         headers:{ "content-type": "application/json" },
//         body: JSON.stringify(body)
//     })
//     const data = await response.json()
//     if(data.status !== 200){
//         removeToken();
//     }
//     localStorage.setItem("__timed_user_token__", JSON.stringify(data.token).replace(/['"]+/g, ""));
//     localStorage.setItem("__timed_user_refreshtoken__", JSON.stringify(data.refresh_token).replace(/['"]+/g, ""));
//     return JSON.stringify(data.token).replace(/['"]+/g, "")
// }

export async function dataFetcher<T>(endpoint: string, { body, ...customConfig }: any = {}, isNoContentType:boolean = false): Promise<T | never> {
    let token = window.localStorage.getItem(localStorageKey);

    const headers: any = !isNoContentType ? { "content-type": "application/json" } : {};
    if (token) {
        const { exp }: any = jwt_decode(token);
        if (Date.now() >= exp * 1000) {
            token = await refreshTokenWithLock();
        }
    }
    headers.Authorization = `Bearer ${token}`;
    const config = {
        method: body ? "POST" : "GET",
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    };
    if (body) {
        config.body = !isNoContentType ? JSON.stringify(body) : body;

    }
    return window.fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config).then(async (response) => {
        if (response.status === 401) {
            if (endpoint === "login") {
                throw new Error("401Error");
            }
            //removeToken();
            throw new Error("pas autorisé");
        }
        if (response.ok) {
            if (response.status === 204) {
                return;
            }
            return await response.json();
        } else {
            const errorMessage = await response.text();
            const errorCode = response.status;
            throw new Error(errorCode + errorMessage);
        }
    });
}

export function removeToken() {
    let paramsF = window.location.search;
    let params = "";
    if(paramsF.length>1){
        params = paramsF;
    }
    localStorage.setItem("q", JSON.stringify(params));
    window.localStorage.removeItem(localStorageKey);
    window.localStorage.removeItem(localStorageKeyR);
    window.location.assign(window.location.href);
}
