import {useQuery, UseQueryResult} from "react-query";
import {getAllRoleNiveau} from "./roleNiveau.services";


export const useGetRoleNiveaux=():UseQueryResult<RoleNiveau[], Error>=>{
    return useQuery<RoleNiveau[], Error>(["roles_niveau"], getAllRoleNiveau, {
        staleTime:60000*10,
        cacheTime:60000*15,
        refetchOnWindowFocus:false,
    })
}