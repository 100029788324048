import {dataFetcher} from "../../services/dataGuetter/dataFetcher";
import {ParamsCTDD} from "./compteurTempsDateDate.hooks";


export async function getCompteurTempsDateDate(dateFrom:string, dateTo:string, users:number[], signal:any, idCentre:number|null):Promise<CompteurTempsDateDate[]>{
    const idsUser = users.join('_');
    const config =  {method:'get'}
    let parms = `dateFrom=${dateFrom}&dateTo=${dateTo}&Users=${idsUser}`
    if(idCentre){
        parms +=`&Centre=${idCentre}`
    }
    const res:any = await dataFetcher(`compteur_temps_date_dates?${parms}`, config)
    return res["hydra:member"];
}

export async function getCompteurTempsDateDateParams(params:ParamsCTDD|null):Promise<CompteurTempsDateDate[]>{
    let parms = "";
    const config = {method: 'get'}
    if(params) {
        const idsUser = params.users.join('_');

        parms = `dateFrom=${params.dateFrom}&dateTo=${params.dateTo}&Users=${idsUser}`
        if (params.idCentre) {
            parms += `&Centre=${params.idCentre}`
        }
    }
    const res:any = await dataFetcher(`compteur_temps_date_dates?${parms}`, config)
    return res["hydra:member"];
}