import styled from "styled-components";
import React, {Suspense, useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {useGetAllUser} from "../../../features/user/user.hooks";
import {useGetFonctions} from "../../../features/fonction/fonction.hooks";
import {LibelleCurrentSmart} from "./LibelleCurrentSmart";
import {ListSmart} from "./ListSmart";
import {GrClose} from "react-icons/gr";
import {ContentSmart, ZoneSearchSmart} from "./styledElt";
import {FaUser} from "react-icons/fa";
import {BsPlus} from "react-icons/bs";
import {IoChevronDown} from "react-icons/io5";

interface TimedSelectPersonneScopeCtrlProps{
    className?:string;
}

interface MyParams{
    index:string,
    from?:string,
    to?:string,
    lieu?:string,
    fx?:string,
    idCollab?:string,
    vues?:string
}

interface ListByFonction{
    libelle:string;
    list:User[];
}
const PathsDisabled:string[] = ["temps_medecin", "equivalent_temps_plein", "list_remplacants"];

const TimedSelectPersonneScopeCtrl = ({className}:TimedSelectPersonneScopeCtrlProps)=>{
    const UsersQuery =  useGetAllUser();
    const FonctionsQuery = useGetFonctions();
    const refObj = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [libelleCurrent, setLibelleCurrent] = useState('');
    const refSearch = useRef<HTMLInputElement>(null)
    const [search, setSearch] = useState('');
    const [openInactif, setOpenInactif] = useState(false)
    const TabFx = searchParams.get("fx");
    const TabCollab = searchParams.get("idCollab");
    const {pathname} = useLocation()
    const [isDisabled, setIsDisabled] = useState(true)
    useEffect(() => {
        const tabPath = pathname.split("/");
        const myPath = tabPath[2];
        setIsDisabled(PathsDisabled.indexOf(myPath)!==-1)
    }, [pathname]);

    const myListFonctions = useMemo(()=>{
        let list:Fonction[] = [];
        const regex = new RegExp(""+search+"", 'gi');
        if(UsersQuery.data){
            const Fonctions = UsersQuery.data.map(u=>u.Fonction);
            const FonctionsSSD = Fonctions.reduce((acc:Fonction[], item:Fonction)=>acc.map(fx=>fx.id).indexOf(item.id)===-1 ? [...acc, item] : acc, [])
            list = FonctionsSSD.filter(f=>f.libelle.match(regex) || search==='').sort((a:Fonction, b:Fonction)=>a.libelle>b.libelle ? 1 : -1)
        }
        return list;
    }, [search, UsersQuery.data])

    const myListUser = useMemo(()=>{
        const list:ListByFonction[] = []
        const regex = new RegExp(""+search+"", 'gi');
        if(FonctionsQuery.data && UsersQuery.data){
            let MyListUser = UsersQuery.data;
            FonctionsQuery.data.forEach(fx=>{
                const UserConcerned = MyListUser.filter(u=>u.Fonction.id === fx.id && u.FonctionAdm.id !== 3 && u.isActif && (search === '' || (`${u.prenom} ${u.nom}`).match(regex)))
                if(UserConcerned.length>0){
                    list.push({libelle:fx.libelle, list:UserConcerned.sort((a:User, b:User)=>a.nom > b.nom ? 1 : -1)})
                }
            })
            const UserReplaced = MyListUser.filter(u=>u.FonctionAdm.id === 3 && u.isActif && (search === '' || (`${u.prenom} ${u.nom}`).match(regex)))
            const UserInactif =MyListUser.filter(u=>!u.isActif && (search === '' || (`${u.prenom} ${u.nom}`).match(regex)))
            if(UserReplaced.length>0){
                const FonctionOne = FonctionsQuery.data.find(f=>f.id === 1);
                if(FonctionOne) {
                    list.push({libelle: FonctionOne.libelle+" Remplaçant", list:UserReplaced.sort((a:User, b:User)=>a.nom > b.nom ? 1 : -1)})
                }
            }
            if(UserInactif.length>0){
                list.push({libelle:"Inactif", list:UserInactif.sort((a:User, b:User)=>a.nom > b.nom ? 1 : -1)})
            }

        }
        return list;
    }, [FonctionsQuery.data, UsersQuery.data, search])
    useEffect(()=>{
        if(FonctionsQuery.data && UsersQuery.data){
            if(TabFx){
                const ids = TabFx.split('#');
                const Fonctions = FonctionsQuery.data.filter(f=>ids.indexOf(f.id.toString())!==-1);
                if(Fonctions){
                    setLibelleCurrent(Fonctions.map(f=>f.libelle).join(', '))
                }
            } else if(TabCollab){
                const ids = TabCollab.split('#');
                const Users = UsersQuery.data.filter(u=>ids.indexOf(u.id.toString())!==-1)
                if(Users){
                    setLibelleCurrent(Users.map(u=>`${u.prenom} ${u.nom}`).join(', '))
                }
            } else {
                setLibelleCurrent('')
            }
        }
    }, [TabCollab, TabFx, FonctionsQuery.data, UsersQuery.data])
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refObj.current && !refObj.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refObj]);
    // const updateSearchParams = (updates: Partial<MyParams>) => {
    //     const currentParams = Object.fromEntries(searchParams.entries());
    //     setSearchParams({ ...currentParams, ...updates });
    // };

    const AddUser = (user:User, option:0|1)=>{
        const currentParams = new URLSearchParams(searchParams);
        currentParams.delete("fx");
        const currentParams2 = Object.fromEntries(currentParams.entries());

        const Exist = TabCollab ?  !!TabCollab.split("#").find(i=>i === user.id.toString()) : false;
        let newTabCollab = TabCollab
            ? TabCollab.split("#").filter((id) => id !== user.id.toString())
            : [];
        if (option === 1) {
            newTabCollab = [user.id.toString()]
            setOpen(false)
        } else if(!Exist) {
            newTabCollab.push(user.id.toString())
        }
        const updates:Partial<MyParams> = { idCollab: newTabCollab.join("#") || undefined }
        setSearchParams({ ...currentParams2, ...updates });
    }
    const AddFonction = (fx:Fonction, option:0|1)=>{
        const currentParams = new URLSearchParams(searchParams);
        currentParams.delete("idCollab");
        const currentParams2 = Object.fromEntries(currentParams.entries());
        const Exist = TabFx ?  !!TabFx.split("#").find(i=>i === fx.id.toString()) : false;
        let newTabFx = TabFx
            ? TabFx.split("#").filter((id) => id !== fx.id.toString())
            : [];
        if (option === 1) {
            newTabFx = [fx.id.toString()]
            setOpen(false)
        } else if(!Exist){
            newTabFx.push(fx.id.toString())
        }
        const updates:Partial<MyParams> = { fx: newTabFx.join("#") || undefined }
        setSearchParams({ ...currentParams2, ...updates });
    }
    const Remove = ()=>{
        const currentParams = new URLSearchParams(searchParams);

        // Supprime les paramètres spécifiés
        currentParams.delete("fx");
        currentParams.delete("idCollab");

        setSearchParams(currentParams);
        setOpen(false);
    }

    const handleChangeSearch = ()=>{
        if(refSearch.current){
            setSearch(refSearch.current.value);
        }
    }
    return (
        <div className={`element_smart_nav timed_select_url ${className}`} ref={refObj}>
            {isDisabled &&
                <div className="voile_disabled"/>
            }
            <LibelleCurrentSmart
                text={libelleCurrent}
                Icon={<FaUser/>}
                themeColor={"Secondary"}
                Actif={libelleCurrent!==''}
                placeholder={"Personne ou fonction"}
                Remove={Remove}
                ClickOnMe={()=>setOpen(o=>!o)}
            />
            <ListSmart open={open} wD={"260px"}>
                <div className={`content-smart`}>
                    <ZoneSearchSmart>
                        <input placeholder={"Recherche"} ref={refSearch} value={search} onChange={handleChangeSearch}/>
                        {search!=='' &&
                            <div className={`close-search`} onClick={()=>setSearch('')}><GrClose/></div>
                        }
                    </ZoneSearchSmart>

                    <ContentSmart>
                        <Suspense fallback={<p>Chargement</p>}>
                            <div className={`one_block`}>
                                <div className="titre">Fonctions</div>
                                    <div className={"content_block"}>
                                        <ul>
                                            {myListFonctions.map((item, idx:number)=>(
                                                <li key={`one_choiceFx${idx}`} className={`one_choice_fx ${(TabFx &&TabFx?.split('#').indexOf(item.id.toString())!==-1) ? 'selected' : ''}`}>
                                                    <div className={`libelle`} onClick={()=>AddFonction(item, 1)}>{item.libelle}</div>
                                                    {(TabFx &&TabFx?.split('#').indexOf(item.id.toString())!==-1) ?
                                                        <div className={`unsel`}>
                                                            <div className={`remove_item`} onClick={()=>AddFonction(item, 0)}><GrClose/></div>
                                                        </div>:
                                                        <div className={`selPlus`} onClick={()=>AddFonction(item, 0)}>
                                                            <div className={`add_item`}>
                                                                <BsPlus/>
                                                            </div>
                                                        </div>
                                                    }
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                            </div>
                        </Suspense>
                        <div className={`one_block`}>
                            <div className="titre">Collaborateurs</div>
                            <div className={"content_block"}>
                                {FonctionsQuery.isLoading ? '...' : FonctionsQuery.isError ? 'error datas':
                                    myListUser.map((item, idx:number)=>(
                                        <div className={`one_block_fonction`} key={`oneBFx${idx}`}>
                                            {item.libelle === "Inactif" ?
                                                <div className={`titre_ss_accordion ${openInactif ? "opened" : "closed"}`} onClick={()=>setOpenInactif(o=>!o)}>
                                                    <div className="lib">{item.libelle}  ({item.list.length})</div>
                                                    <div className={`wrap_chevron`}><IoChevronDown /></div>
                                                </div>:
                                                <div className={`titre_ss`}>
                                                    <span>{item.libelle}</span>
                                                </div>
                                            }
                                            <div className={`content_block_fonction ${item.libelle === "Inactif" ? "is_accordion" : ""} ${openInactif ? "opened" : "closed"}`}>
                                                <ul>
                                                {item.list.map((itemU)=>(
                                                    <li key={`one_choice_user${itemU.id}`} className={`one_choice_user  ${(TabCollab && TabCollab.split('#').indexOf(itemU.id.toString())!==-1) ? 'selected' : ''}`}>
                                                        <div className={`libelle`} onClick={()=>AddUser(itemU, 1)}>{itemU.prenom} {itemU.nom}</div>
                                                        {(TabCollab &&TabCollab?.split('#').indexOf(itemU.id.toString())!==-1) ?
                                                            <div className={`unsel`}>
                                                                <div className={`remove_item`} onClick={()=>AddUser(itemU, 0)}><GrClose/></div>
                                                            </div>:
                                                            <div className={`selPlus`}>
                                                                <div className={`selPlus`} >
                                                                    <div className={`add_item`} onClick={()=>AddUser(itemU, 0)}>
                                                                        <BsPlus/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }

                                                    </li>
                                                ))}
                                                </ul>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </ContentSmart>
                </div>
            </ListSmart>
        </div>
    )
}

export const TimedSelectPersonneScope = styled(TimedSelectPersonneScopeCtrl)`
  position: relative;
    .voile_disabled{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255,255,255,.7);
    }
  .titre_ss{
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 5px;
    padding: 0.25rem 0.5rem;
  }
    .content_block_fonction.is_accordion{
        overflow: hidden;
        &.closed{
            height: 0;
        }
        &.opened{
            height: auto;
        }
    }
    .titre_ss_accordion{
        margin-bottom: 5px;
        padding: 0.25rem 0.5rem;
        display: flex;
        justify-content: flex-start;
        gap: 4px;
        align-items: center;
        &:hover{
            cursor: pointer;
        }
        .lib{
            text-decoration: underline;
            font-weight: bold;
        }
        .wrap_chevron{
            display: flex;
            justify-content: center;
            flex-direction: column;
            transition: all 0.3s;
        }
        &.opened{
            .wrap_chevron{
                transform: rotate(180deg);
                transition: all 0.3s;
            }
        }
    }
  .one_block_fonction{
    margin-bottom: 10px;
  }
  .one_block{
    font-size: 14px;
    margin-bottom: 10px;
    .titre{
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 5px;
      border-bottom: 1px solid ${props => props.theme.SecondaryExtraLight};
    }
    .content_block{
      ul{
        margin: 0;
        padding: 0;
        li{
          
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5px;
          margin-bottom: 2px;
          .libelle{
            flex-grow: 1;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0.25rem 0.5rem 0.25rem  0.75rem;
          }
          .unsel{
            padding-right: 3px;
            &:hover{
              cursor: pointer;
            }
            
          }
          .selPlus{
            opacity: 0;
            .add_item{
              padding-left: 8px;  
            }
            &:hover{
              cursor: pointer;
            }
          }
          .add_item{
            margin: auto;
            transition: padding-left 200ms;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 22px;
            height: 22px;
            font-size: 22px;
            
            svg{
              margin: auto;
              path{
                color: ${props=>props.theme.Secondary};
              }
            }
            &:hover{
              background: ${props=>props.theme.SecondaryDark};
              color: ${props=>props.theme.SecondaryExtraLight};
              svg path{
                color:${props=>props.theme.SecondaryExtraLight};
              }
            }
          }
          .remove_item{
            border-radius: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 22px;
            height: 22px;
            font-size: 14px;
            svg{
              margin: auto;
              
            }
            &:hover{
              background: ${props=>props.theme.SecondaryDark};
              color: ${props=>props.theme.SecondaryExtraLight};
              svg path{
                stroke:${props=>props.theme.SecondaryExtraLight};
              }
            }
          }
          &:not(.selected):hover{
            .libelle{
              background: ${props => props.theme.SecondaryMegaLight};
              transition: background-color 300ms;
              font-weight: bold;
            }
            .selPlus{
              opacity: 1;
              .add_item{
                padding-left: 0px;
                transition: padding-left 200ms;
              }
              
            }
            cursor: pointer;
          }
          &.selected{
            font-weight: bold;
            background: ${props=>props.theme.SecondaryExtraLight};
            color: ${props=>props.theme.SecondaryExtraDark};
            border-radius: 12px;
          }
        }
      }
    }
  }        
`