import styled from "styled-components";
import React, {Suspense, useEffect, useState} from "react";
import ReactDOM from "react-dom";
import {GrClose} from "react-icons/gr";
import TimedDashWaiter from "../../atoms/TimedWaitUser/TimedDashWaiter";

interface TimedModalCentral2CtrlProps{
    className?:string;
    open:boolean;
    titre?:string;
    Pending:boolean
    children:React.ReactNode
    closeMe:()=>void;
    Wd:string;
    noOverFlowHidden?:boolean;
    noClosable?:boolean;
    isVerticalCenter?:boolean;
    ClosableShadow?:boolean;
    suspenser?:boolean;
}

const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;
const TimedModalCentral2Ctrl = ({className, open, Pending, titre, children, closeMe, noClosable, ClosableShadow, ...rest}:TimedModalCentral2CtrlProps)=>{
    const [state, setState] = useState(open ? VISIBLE : HIDDEN);
    useEffect(()=>{
        if(!open){
            setState(LEAVING)
        } else{
            setState((s)=>s===HIDDEN ? ENTERING : LEAVING)
        }
    }, [open])
    useEffect(()=>{
        if(state === LEAVING){
            const timer = setTimeout(()=>{
                setState(HIDDEN)
            }, 300)
            return ()=>{
                clearTimeout(timer);
            }
        } else if (state === ENTERING){
            const xx = document.body.offsetHeight
            setState(VISIBLE);
        }
    }, [state])
    const ClickShadow = ()=>{
        if(ClosableShadow){
            closeMe();
        }
    }

    const style = {transitionDuration: `300ms`, transitionProperty:"opacity", opacity:1}
    const styleModal = {transitionDuration: `300ms`, transitionProperty:"opacity, transform", opacity:1, transform:"translate(-50%, -50%)"}
    if(state!==VISIBLE){
        style.opacity = 0;
        styleModal.opacity = 0;
        styleModal.transform = "translate(-50%, -30%)"
    }

    if(state === HIDDEN){
        return null;
    } else {
        return ReactDOM.createPortal(
            <div className={`timed_modal ${className}`}>
                <div style={style} className={`back-drop`} onClick={ClickShadow}/>
                <div className={`modal_content`} style={styleModal}>
                    <div className={`header_modal`}>
                        <div className={`titre_modal`}>{titre ? titre : 'Confirmation'}</div>
                        {!noClosable &&
                            <div className={`close_modal`} onClick={()=>closeMe()}><GrClose/></div>
                        }
                    </div>
                    <div className={`body_modal`}>
                        {rest.suspenser ?
                            <Suspense fallback={<TimedDashWaiter/>}>
                                {children}
                            </Suspense>:
                            children
                        }
                    </div>
                </div>
            </div>, document.body
        )
    }
}


export const TimedModalCentral2 = styled(TimedModalCentral2Ctrl)`
  .plage_suppr{
    margin-bottom: 15px;
    text-align: center;
    .user{font-weight: bold}
  }
  .back-drop{
    position:fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.7);
    z-index: 10;
  }
  .modal_content{
    height: auto;
      
    background: white;
    width: ${props => props.Wd};
    border-radius: 12px;
    position: fixed;
    
    left: 50%;
    top: ${props=>props.isVerticalCenter ? "50%" : "40%"};
    z-index: 10;
    overflow: ${props=>props.noOverFlowHidden ? 'visible' : 'hidden'};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    .body_modal{
      flex-grow: 1;
      padding: 1rem;
        max-height: 85vh;
        overflow-y: auto;
      p{
        text-align: center;
        margin-bottom: 20px;
      }
      .wrap_btns{
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
      }
    }
    .header_modal{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0.5rem;
      align-content: center;
      .titre_modal{
        flex-grow: 1;
        font-weight: bold;
        font-size: 14px;
      }
      .close_modal{
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
`