import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";
import {Suspense, useContext, useEffect, useState} from "react";
import {SectionContext} from "./OneSection";
import {useGetNumberModule} from "../../../features/moduleFxNumber/moduleFxNumber.hooks";
import {useGetCentreActuel} from "../../../features/centre/centre.hooks";

interface TimedSideLinkCtrlProps{
    className?:string;
    Module:Module;
}

const TimedSideLinkCtrl = (props:TimedSideLinkCtrlProps)=>{
    const { search } = useLocation();
    const location = useLocation();
    const [current, setCurrent] = useState(false);
    const [nb, setNb] = useState(0)
    const FxNumberQuery = useGetNumberModule(props.Module.fxNumber ? +props.Module.id :0)
    useEffect(() => {
        if(FxNumberQuery.data){
            setNb(FxNumberQuery.data.volume);
        }
    }, [FxNumberQuery.data]);
    useEffect(() => {
        const TabPath = location.pathname.split('/');
        const myPath = TabPath[2];
        setCurrent(myPath === props.Module.url);
    }, [location.pathname, props.Module]);
    const GetUrl = ()=>{
        return props.Module.url
    }
    return (
        <NavLink to={`${GetUrl()}${search}`} className={`timed_side_link ${props.className} ${current ? 'is_current' : ''} ${props.Module.isGroup ? "marg_top" : ""}`}>
            {props.Module.libelle}
            {nb!==0 &&
                <Suspense fallback={<></>}>
                    <span className="numberX">({nb})</span>
                </Suspense>
            }
        </NavLink>
    )
}

export const TimedSideLink = styled(TimedSideLinkCtrl)`
  display: block;
  padding: 0.4rem 0.5rem 0.4rem 0.75rem;
    margin-bottom: 2px;
  text-decoration: none;
  color: white;
    border-radius: 0px;
    &.marg_top{
        padding-top: 8px;
        margin-top: 8px;
        border-top: solid white 1px;
    }
    .numberX{
        margin-left: 4px;
        font-weight: bold;
        color: ${props => props.theme.SecondaryExtraLight};
    }
  &:visited{
    color: white;
  }
  &:hover, &.is_current{
      border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    background: ${props=>props.theme.PrimaryExtraLight};
    color: ${props=>props.theme.PrimaryDark};
      .numberX{
          color: ${props=>props.theme.PrimaryDark};
      }
  }
`

