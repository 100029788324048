import styled from "styled-components";
import {useGetSections} from "../../../../features/module/module.hooks";
import React, {useMemo, useState} from "react";
import {useGetCentreActuel} from "../../../../features/centre/centre.hooks";
import OneSectionStyle2 from "./components/OneSectionStyle2";
import {version_timed} from "../../../../index";

interface TimedSideBarStyle2CtrlProps{
    className?:string;
}

const TimedSideBarStyle2Ctrl = (props:TimedSideBarStyle2CtrlProps)=>{
    const CentreQuery = useGetCentreActuel();
    const [idCentre, setIdCentre] = useState(0);
    const SectionsQuery = useGetSections();
    const listSections = useMemo(()=>{
        const list:Section[] = [];
        if(SectionsQuery.data && CentreQuery.data){
            SectionsQuery.data.forEach(s=>{
                const Params = s.Parameter;
                if(Params){
                    const centreParams = CentreQuery.data.parameters;
                    const MyParams = centreParams.find(cp=>cp.Parameter.id === Params.id);
                    if(MyParams && MyParams.statut){
                        list.push(s);
                    }
                } else {
                    list.push(s);
                }
            })

        }
        return list
    }, [SectionsQuery.data, CentreQuery.data])
    return (
        <div className={`timedSideBarStyle2 ${props.className}`}>
            <div className="content_sections">
                {listSections.map(item => (
                    <OneSectionStyle2 Section={item} key={`SS2${item.id}`}/>
                ))}
            </div>
            <div className={"num_version"}>{CentreQuery.data?.name}<br/>{version_timed}</div>
        </div>
    )
}

const TimedSideBarStyle2 = styled(TimedSideBarStyle2Ctrl)`
    display: flex;
    flex-direction: column;
    padding: 2rem 0.5rem;
    background: ${props=>props.theme.PrimarySuperDark};
    .content_sections{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
    .num_version{
        font-size: 13px;
        text-align: center;
        margin-top: 14px;
        color: white;
    }
`

export default TimedSideBarStyle2